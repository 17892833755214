import { gql } from "@apollo/client";
import { VEHICLES_FOR_RACE_FIELDS } from "../fragments";

export const RACE_LEASED_VEHICLE_SELECTION = gql`
  ${VEHICLES_FOR_RACE_FIELDS}

  query RaceLeasedVehicleSelection(
    $address: String!
    $raceClass: String!
    $vehicleTypes: String_comparison_exp
    $search: String_comparison_exp
    $sortBy: token_metadata_order_by
  ) {
    lease_contracts(
      where: {
        status: { _eq: "active" }
        _not: {
          vehicle: {
            metadata: {
              race_participant: {
                race: { status: { _in: ["upcoming", "next_to_race"] } }
                address: { _eq: $address }
              }
            }
          }
        }
        leaser_address: { _regex: $address }
        vehicle: {
          metadata: {
            attributes: {
              transportation_mode: { _eq: $raceClass }
              vehicle_type: $vehicleTypes
            }
            name: $search
          }
        }
      }
      order_by: { vehicle: { metadata: $sortBy } }
    ) {
      vehicle {
        metadata {
          ...VehicleForRaceFields
        }
      }
    }
  }
`;
