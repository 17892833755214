import { gql } from "@apollo/client";

export const LEASE_CONTRACT = gql`
  query LeaseContract($id: Int!) {
    lease_contracts(
      where: { status: { _eq: "active" }, token_id: { _eq: $id } }
    ) {
      created_at
      token_id
      owner_address
      leaser_address
      leaser {
        username
      }
      lease: lease_terms {
        terms: lease_terms
        lease_fee
        winnings_split
        duration
        max_races
      }
    }
  }
`;
