import { useEffect } from "react";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useAtom, useSetAtom } from "jotai";
import { userWalletAtom } from "../store";
import { walletModalAtom } from "../store/modals";
import { chainIds } from "src/utils/chainIds";
import formatAddress from "../utils/formatAddress";

const walletConnectProvider = new WalletConnectProvider({
  infuraId: "4616c82f7e534b57967b566621e5ed39",
  rpc: {
    1: "https://eth-mainnet.alchemyapi.io/v2/kuynj6FkT2d6xovtLQQ2tSQWnnuHfMI1",
  },
});

export default function useTestWallet() {
  // const address = "0xCeEf845d71Ce6bAeb2cDfF2da8B0ebCb4283A88a";
  // const address = "0x1A2683A648511Cf97C7B52E194F420664259D6EE";
  const address = "0x4dbADf8C450CCE58E560812cD0C7998DA1040f26";

  const [wallet, setWallet] = useAtom(userWalletAtom);
  const setShowWalletModal = useSetAtom(walletModalAtom);

  const updateWallet = ({ address, provider, network }) => {
    setWallet({
      address,
      network,
      provider,
      text: "Connected: " + formatAddress(address),
    });
  };

  useEffect(() => {
    const network = chainIds[window.ethereum.networkVersion] ?? null;
    setWallet({
      ...wallet,
      address,
      network,
      text: "Connected: " + formatAddress(address),
    });
    // eslint-disable-next-line
  }, []);

  const handleConnectWallet = async (type) => {
    if (type === "WalletConnect") {
      await walletConnectProvider.enable();
      const web3 = new Web3(walletConnectProvider);
      const accounts = await web3.eth.getAccounts();

      if (accounts.length > 0) {
        updateWallet({
          address: accounts[0],
          provider: "WalletConnect",
        });
        setShowWalletModal(false);
      }

      walletConnectProvider().on("accountsChanged", (accounts) => {
        updateWallet({
          address: accounts[0],
          provider: "WalletConnect",
        });
      });
      walletConnectProvider().on("disconnect", () => {
        setWallet({
          address: null,
          network: null,
          provider: "Metamask",
          text: "Connect Wallet",
        });
      });
    } else {
      if (window.ethereum) {
        try {
          const addresses = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const address = utils.getAddress(addresses[0]);
          const network = chainIds[window.ethereum.networkVersion];
          updateWallet({ address, provider: "Metamask", network });
          setShowWalletModal(false);
        } catch (error) {
          setWallet({
            address: null,
            network: null,
            provider: "Metamask",
            text: "😥 " + error.message,
          });
          setShowWalletModal(false);
        }
      }
    }
  };

  return handleConnectWallet;
}
