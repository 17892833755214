const theme = {
  screens: {
    _: 0,
    sm_mobile: 350,
    mobile: 375,
    lg_mobile: 420,
    sm_tablet: 520,
    tablet: 640,
    md_tablet: 670,
    lg_tablet: 768,
    sm_desktop: 992,
    desktop: 1024,
    lg_desktop: 1280,
    xl_desktop: 1420,
    xxl_desktop: 1745,
  },
};

export default theme;
