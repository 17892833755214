import { gql } from "@apollo/client";

export const VEHICLE_STATS = gql`
  fragment VehicleStats on token_metadata {
    stats: attributes {
      max_capacity
      max_range
      max_speed
      fuel_efficiency
      emission_rate
    }
  }
`;
