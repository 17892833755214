import { gql } from "@apollo/client";
import { VEHICLE_FIELDS, VEHICLE_STATS } from "../fragments";

export const VEHICLE = gql`
  ${VEHICLE_FIELDS}
  ${VEHICLE_STATS}

  query Vehicle($id: Int) {
    token_metadata(where: { token: { id: { _eq: $id } } }) {
      ...VehicleFields
      ...VehicleStats
      attributes {
        transportation_mode
        vehicle_type
        background_color
        color
        slogan
        sign
        food
        animal
        mention
        other
      }
      token {
        address
        punk {
          token_id
          name
          image
          xp: xp_earned
          level
          skill_points
        }
      }
    }
  }
`;
