import { atom } from "jotai";
import { atomFamily, atomWithReset } from "jotai/utils";

// Vehicles to be leased out
export const vehiclesToLeaseAtom = atom(null);

// User's vehicle listing
export const vehicleListingAtom = atom(null);

// Lease contract info
export const leaseContractAtom = atom(null);

// Vehicles leased in by an user
export const userLeasesAtom = atom([]);

// View mode on leasing page (table or grid)
export const leasingViewAtom = atom("table");

// Filters on leasing page
export const leasingFiltersAtom = atomWithReset({
  leaseType: "all",
  raceClasses: [],
  vehicleTypes: [],
  periodType: "all",
  races: null,
  duration: null,
  priceType: "all",
  price: null,
  percentage: null,
});

// Sort leasing by
export const leasingSortAtom = atomWithReset(null);

// Pagination of races
export const leasingPaginationAtom = atomWithReset({
  pages: 0,
  currentPage: 1,
  limit: 20,
  loading: false,
});

// Leasing filters sidebar toggle
export const leasingFiltersSidebarAtom = atomWithReset({
  open: false,
  action: null,
});

export const leasingFiltersSidebarToggleAtom = atom(
  (get) => get(leasingFiltersSidebarAtom),
  (get, set, _arg) => {
    const sidebar = get(leasingFiltersSidebarAtom);
    return set(leasingFiltersSidebarAtom, {
      open: !sidebar.open,
      action: sidebar.open ? "close" : "open",
    });
  }
);

// Fixed price filter (range)
const leasingPriceFilterAtoms = atomFamily((value) => atom(value));

export const leasingPriceMinAtom = (min) => {
  return leasingPriceFilterAtoms(min || 0);
};
export const leasingPriceMaxAtom = (max) => {
  return leasingPriceFilterAtoms(max || 5000);
};

// % from winnings filter (range)
const leasingPercentageFilterAtoms = atomFamily((value) => atom(value));

export const leasingPercentageMinAtom = (min) => {
  return leasingPercentageFilterAtoms(min || 0);
};
export const leasingPercentageMaxAtom = (max) => {
  return leasingPercentageFilterAtoms(max || 100);
};

// Number of races [leasing period] (range)
const leasingNoOfRacesAtoms = atomFamily((value) => atom(value));

export const leasingNoOfRacesMinAtom = (min) => {
  return leasingNoOfRacesAtoms(min || 0);
};
export const leasingNoOfRacesMaxAtom = (max) => {
  return leasingNoOfRacesAtoms(max || 1000);
};

// Lease form status
export const leaseFormStatusAtom = atom("idle");

// Status of leasing a vehicle in
export const leasingStatusAtom = atom("idle");

// Status of leasing admin user authorization
export const leasingAuthAtom = atom(false);
