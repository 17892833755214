import { gql } from "@apollo/client";
import { RACE_FIELDS } from "../fragments";

export const MY_AREA_RACES = gql`
  ${RACE_FIELDS}

  query MyAreaRaces(
    $address: String!
    $raceClasses: String_comparison_exp
    $entryFee: Int_comparison_exp
    $distance: Float_comparison_exp
    $period: timestamptz_comparison_exp
    $cargo: Int_comparison_exp
    $sortBy: [races_order_by!]
    $limit: Int
  ) {
    races(
      where: {
        status: { _eq: "archived" }
        participants: { address: { _regex: $address } }
        class: $raceClasses
        entry_fee: $entryFee
        distance: $distance
        start: $period
        weight: $cargo
      }
      order_by: $sortBy
      limit: $limit
    ) {
      ...RaceFields
    }
    races_aggregate(
      where: {
        status: { _eq: "archived" }
        participants: { address: { _regex: $address } }
        class: $raceClasses
        entry_fee: $entryFee
        distance: $distance
        start: $period
        weight: $cargo
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
