import { gql } from "@apollo/client";

export const RACER = gql`
  query Racers($address: String!) {
    racers(where: { address: { _regex: $address } }) {
      address
      username
      races_won
      tokens_won
      tokens_to_claim
    }
  }
`;
