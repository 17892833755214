import { gql } from "@apollo/client";
import { VEHICLE_FIELDS } from "./vehicle.fragment";
import { VEHICLE_STATS } from "./vehicleStats.fragment";

export const RACE_FIELDS = gql`
  ${VEHICLE_FIELDS}
  ${VEHICLE_STATS}

  fragment RaceFields on races {
    id
    race_id: polygon_race_id
    name
    class
    distance
    weather
    terrain
    weight
    cargo
    fromCoord
    toCoord
    prize_pool
    entry_fee
    start
    status
    sponsor
    promo_link
    promo_banner
    participants {
      racer {
        address
        username
      }
      vehicle {
        ...VehicleStats
        ...VehicleFields
        freightPunk: token {
          punk {
            image
          }
        }
      }
    }
    results {
      time
      place
      prize
      racer {
        address
        username
      }
      vehicle {
        ...VehicleStats
        ...VehicleFields
      }
    }
    participants_aggregate {
      aggregate {
        count
      }
    }
    results_aggregate {
      aggregate {
        count
      }
    }
  }
`;
