import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";

// Wallet connection modal toggle
export const walletModalAtom = atom(false);

// Modals pertaining to claiming WOF tokens
export const claimModalsAtom = atom({
  open: false,
  lockedTokens: false,
  success: false,
  failure: false,
});

// Modals pertaining to editing tokens in Garage
export const garageModalsAtom = atomWithReset({
  edit: {
    name: false,
    background: false,
    color: false,
    slogan: false,
    upgrade: false,
  },
  success: {
    name: false,
    background: false,
    color: false,
    slogan: false,
    upgrade: false,
  },
});

// Vehicle upgrade modal toggle
export const upgradeModalAtom = atom(false);

// Modals pertaining to junkyard actions
export const burnModalsAtom = atom({
  stepOne: false,
  stepTwo: false,
  success: false,
  failure: false,
  lackingFunds: false,
});

// Modals pertaining to Freight Punk minting
export const freightPunkModalsAtom = atom({
  success: false,
  failure: false,
});

// Modal for confirming punk (as driver) removal if user leasing vehicle
// with function for removing the punk
export const driverRemovalModalAtom = atomWithReset({
  open: false,
  loading: false,
});

// Modals pertaining to swapping $WOF
export const bridgeAtom = atomWithReset({
  amount: null,
  intro: false,
  deposit: false,
  confirm: false,
  transfer: false,
  error: false,
  tx: {
    approved: false,
    confirmed: false,
    completed: false,
    hash: null,
  },
});

// Modals pertaining to joining a (paid) race
export const raceJoinModalsAtom = atomWithReset({
  show: false,
  transfer: false,
  confirmation: false,
  loading: false,
  error: false,
});
