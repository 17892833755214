import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { apolloClient } from "src/lib/apollo";
import { FLEET } from "src/graphql/queries";
import { useSetAtom, useAtomValue } from "jotai";
import { userWalletAtom, userFleetAtom, fleetFiltersAtom } from "src/store";
import logError from "src/utils/logError";

export default function useFleet() {
  const { address } = useAtomValue(userWalletAtom);
  const { raceClasses, sortBy } = useAtomValue(fleetFiltersAtom);

  const initialClasses = ["Ground", "Water", "Air", "Space"];
  const setFleet = useSetAtom(userFleetAtom);

  const [fetchFleet, { refetch }] = useLazyQuery(FLEET, {
    client: apolloClient,
    variables: {
      address,
      classes: raceClasses.length ? raceClasses : initialClasses,
      sortBy,
    },
    onCompleted: (data) => setFleet(data.token_metadata),
    onError: (error) => logError({ error }),
  });

  useEffect(() => {
    address && fetchFleet();
  }, [address, raceClasses, sortBy, fetchFleet]);

  return refetch;
}
