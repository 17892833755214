import { useEffect } from "react";
import { useRouter } from "next/router";

export default function useRouteChange(callback, timing) {
  const router = useRouter();
  const event = timing === "end" ? "routeChangeComplete" : "routeChangeStart";

  useEffect(() => {
    router.events.on(event, callback);
    return () => router.events.off(event, callback);
    // eslint-disable-next-line
  }, [router.events]);
}
