import { useEffect } from "react";
import Head from "next/head";
import Script from "next/script";

import { ApolloProvider } from "@apollo/client";

import { apolloClient } from "src/lib/apollo";
import GoogleTagManager from "src/lib/googleTagManager";
import FacebookPixel from "src/lib/facebookPixel";

import useAppVersion from "hooks/useAppVersion";
import useWallet from "hooks/useWallet";
import useTestWallet from "hooks/useTestWallet";
import useFleet from "hooks/vehicles/useFleet";
import useLeasedVehicles from "hooks/leasing/useLeasedVehicles";
import useFreightPunks from "hooks/punks/useFreightPunks";
import useAccountBalance from "hooks/useAccountBalance";

import { ThemeProvider } from "@xstyled/styled-components";
import theme from "styles/theme";

import "styles/globals.css";
import "swiper/css/bundle";
import "aos/dist/aos.css";

import AOS from "aos";

import Toaster from "@/UI/Toaster";

// import Announcement from "@/UI/Announcement";
import { useAtom } from "jotai";
import { announcementPopoverAtom } from "src/store";

export default function WOF({ Component, pageProps }) {
  // const [open] = useAtom(announcementPopoverAtom);

  // Handle app version
  useAppVersion();

  // Set up scroll animations
  useEffect(() => {
    if (typeof window !== "undefined") {
      AOS.init({
        offset: 100,
        delay: 150,
        duration: 850,
        easing: "ease",
        mirror: true,
        once: true,
      });
    }
  });

  // Handle wallet connection
  useWallet();
  // useTestWallet();

  // Handle user's vehicles
  useFleet();

  // Handle vehicles leased in by the user
  useLeasedVehicles();

  // Handle user's Freight Punks
  useFreightPunks();

  // Handle user's token balance
  useAccountBalance();

  return (
    <>
      <Head>
        <title>World of Freight</title>
        <meta
          name="description"
          content="The World of Freight is a collection of 10,000 NFTs about vehicles used in the world of freight — built on the Ethereum blockchain and with a unique utility."
        />
        <meta
          name="google-site-verification"
          content="6Iafr6aXffTSp3kt68rn_KqCEuhP6Kek4fWRORehw_Y"
        />
        <meta
          name="facebook-domain-verification"
          content="dkzq59hbsgueg3opdx9mmriacqmfsa"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {/*<Script
        data-clientid="gwcdg7xi20z2o95xkhemvzu1tqst78n0"
        async
        src="https://du6e3ycnwcp7x.cloudfront.net/js/cw.min.js"
      />*/}
      <GoogleTagManager />
      <FacebookPixel />
      <ThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <Toaster />
          {/* {open && <Announcement />} */}
          <Component {...pageProps} />
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
}
