import { useEffect } from "react";
import meta from "../../package.json";

export default function useAppVersion() {
  const APP_VERSION = meta.version;
  const name = "wof_app_version";

  // Reload app if not latest version
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!localStorage.hasOwnProperty(name)) {
        localStorage.setItem(name, APP_VERSION);
      } else {
        const LOCAL_VERSION = localStorage.getItem(name);

        if (LOCAL_VERSION !== APP_VERSION) {
          localStorage.setItem(name, APP_VERSION);
          window.location.reload();
        }
      }
    }
  }, [APP_VERSION]);
}
