import { gql } from "@apollo/client";
import { VEHICLE_FIELDS, VEHICLE_STATS } from "../fragments";

export const VEHICLES_LEASED = gql`
  ${VEHICLE_FIELDS}
  ${VEHICLE_STATS}

  query LeasedVehicles(
    $address: String!
    $classes: [String!]
    $sortBy: token_metadata_order_by
  ) {
    lease_contracts(
      where: {
        status: { _eq: "active" }
        leaser_address: { _regex: $address }
        vehicle: {
          metadata: { attributes: { transportation_mode: { _in: $classes } } }
        }
      }
      order_by: { vehicle: { metadata: $sortBy } }
    ) {
      lease: lease_terms {
        duration
        max_races
        races_raced
      }
      created_at
      vehicle {
        metadata {
          attributes {
            vehicle_type
          }
          ...VehicleFields
          ...VehicleStats
          freightPunk: token {
            punk {
              token_id
              image
            }
          }
        }
      }
    }
  }
`;
