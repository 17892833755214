import { gql } from "@apollo/client";
import { VEHICLES_FOR_RACE_FIELDS } from "../fragments";

export const RACE_VEHICLE_SELECTION = gql`
  ${VEHICLES_FOR_RACE_FIELDS}

  query RaceVehicleSelection(
    $address: String!
    $raceClass: String!
    $vehicleTypes: String_comparison_exp
    $search: String_comparison_exp
    $sortBy: [token_metadata_order_by!]
  ) {
    token_metadata(
      where: {
        _not: {
          race_participant: {
            race: { status: { _in: ["upcoming", "next_to_race"] } }
            address: { _eq: $address }
          }
        }
        token: { address: { _regex: $address }, status: { _eq: "owned" } }
        attributes: {
          transportation_mode: { _eq: $raceClass }
          vehicle_type: $vehicleTypes
        }
        name: $search
      }
      order_by: $sortBy
    ) {
      ...VehicleForRaceFields
    }
  }
`;
