import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { HASURA_API_URL, HASURA_ADMIN_SECRET } from "src/constants";

const httpLink = new HttpLink({ uri: HASURA_API_URL });

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "Content-Type": "application/json",
      "x-hasura-role": "user",
      'x-hasura-admin-secret': HASURA_ADMIN_SECRET,
    },
  };
});
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
  },
  query: {
    fetchPolicy: "network-only",
  },
};

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions,
});
