import { useEffect } from "react";
import { utils } from "ethers";
import Web3 from "web3";
import { useAtom, useSetAtom } from "jotai";
import { userWalletAtom } from "../store";
import { walletModalAtom } from "../store/modals";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { chainIds } from "src/utils/chainIds";
import formatAddress from "src/utils/formatAddress";

const walletConnectProvider = new WalletConnectProvider({
  infuraId: "ec429ca798e74b668fb15ab33206b2d4",
});

export default function useWallet() {
  const [wallet, setWallet] = useAtom(userWalletAtom);
  const setShowWalletModal = useSetAtom(walletModalAtom);

  const updateWallet = ({ address, provider, network }) => {
    setWallet({
      address,
      network,
      provider,
      text: "Connected: " + formatAddress(address),
    });
  };

  useEffect(() => {
    if (window.ethereum) {
      const connectToMetaMask = async () => {
        setWallet({ ...wallet, text: "Loading..." });
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();

        if (accounts.length > 0) {
          const network = chainIds[window.ethereum.networkVersion];
          updateWallet({ address: accounts[0], provider: "Metamask", network });
        } else {
          setWallet({ ...wallet, text: "Connect Wallet" });
        }
      };

      connectToMetaMask();

      window.ethereum.on("networkChanged", async (id) => {
        setWallet({ ...wallet, text: "Loading..." });
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        const network = chainIds[id];
        setWallet({ ...wallet, address: accounts[0], network });
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleConnectWallet = async (type) => {
    if (type !== "Metamask") {
      localStorage.removeItem("walletconnect");
      await walletConnectProvider.enable();

      const web3 = new Web3(walletConnectProvider);
      const accounts = await web3.eth.getAccounts();
      const networkId = await web3.eth.net.getId();
      const network = chainIds[networkId];

      if (accounts.length > 0) {
        updateWallet({
          address: accounts[0],
          provider: "WalletConnect",
          network,
        });
        setShowWalletModal(false);
      }

      walletConnectProvider().on("accountsChanged", async (accounts) => {
        const web3 = new Web3(walletConnectProvider);
        const networkId = await web3.eth.net.getId();
        const network = chainIds[networkId];

        updateWallet({
          address: accounts[0],
          provider: "WalletConnect",
          network,
        });
      });
      walletConnectProvider().on("disconnect", () => {
        setWallet({
          address: null,
          network: null,
          provider: "Metamask",
          text: "Connect Wallet",
        });
      });
    } else if (window.ethereum) {
      try {
        const addresses = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const address = utils.getAddress(addresses[0]);
        const network = chainIds[window.ethereum.networkVersion];

        updateWallet({ address, provider: "Metamask", network });
        setShowWalletModal(false);
      } catch (error) {
        setWallet({
          address: null,
          network: null,
          provider: "Metamask",
          text: "😥 " + error.message,
        });
        setShowWalletModal(false);
      }
    }
  };

  return handleConnectWallet;
}
