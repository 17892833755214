import { useEffect } from "react";
import axios from "axios";
import { useAtomValue, useSetAtom } from "jotai";
import { userWalletAtom, userBalanceAtom } from "../store";
import logError from "src/utils/logError";

export default function useAccountBalance() {
  const { address } = useAtomValue(userWalletAtom);
  const setBalance = useSetAtom(userBalanceAtom);

  useEffect(() => {
    if (address) {
      axios
        .post("/api/balance", { address })
        .then(({ data }) => {
          const { eth, polygon, unclaimed, locked, unlocked } = data;
          setBalance({
            eth,
            polygon,
            unclaimed,
            locked,
            unlocked,
          });
        })
        .catch((error) => logError({ error }));
    }
  }, [address, setBalance]);
}
