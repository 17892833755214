import { gql } from "@apollo/client";
import { VEHICLE_FOR_LEASE_FIELDS } from "../fragments";

export const VEHICLES_TO_LEASE = gql`
  ${VEHICLE_FOR_LEASE_FIELDS}

  query VehiclesToLease(
    $sortBy: [token_leases_order_by!]
    $limit: Int
    $type: String_comparison_exp
    $fee: Int_comparison_exp
    $races: Int_comparison_exp
    $percentage: Float_comparison_exp
    $duration: Int_comparison_exp
    $raceClasses: String_comparison_exp
    $vehicleTypes: String_comparison_exp
  ) {
    token_leases(
      where: {
        status: { _eq: "confirmed" }
        lease_terms: $type
        lease_fee: $fee
        duration: $duration
        max_races: $races
        winnings_split: $percentage
        metadata: {
          attributes: {
            transportation_mode: $raceClasses
            vehicle_type: $vehicleTypes
          }
        }
      }
      order_by: $sortBy
      limit: $limit
    ) {
      ...VehicleForLeaseFields
      token {
        punk {
          name
        }
      }
    }
    token_leases_aggregate(
      where: {
        status: { _eq: "confirmed" }
        lease_terms: $type
        lease_fee: $fee
        duration: $duration
        max_races: $races
        winnings_split: $percentage
        metadata: {
          attributes: {
            transportation_mode: $raceClasses
            vehicle_type: $vehicleTypes
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
