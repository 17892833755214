import { gql } from "@apollo/client";
import { VEHICLE_FIELDS } from "../fragments";

export const FLEET = gql`
  ${VEHICLE_FIELDS}

  query Fleet(
    $address: String!
    $classes: [String!]
    $sortBy: [token_metadata_order_by!]
  ) {
    token_metadata(
      where: {
        token: { address: { _regex: $address } }
        attributes: { transportation_mode: { _in: $classes } }
      }
      order_by: $sortBy
    ) {
      ...VehicleFields
    }
  }
`;
