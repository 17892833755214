import { gql } from "@apollo/client";

export const ATTRIBUTES = gql`
  query Attributes($attributes: [attributes_bool_exp!]) {
    attributes(where: { _or: $attributes }) {
      value
      rarity
    }
  }
`;
