export const env = process.env.NEXT_PUBLIC_NODE_ENV;

export const API_URL =
  env === "dev" ? "http://localhost:8000" : process.env.NEXT_PUBLIC_API_URL;

export const HASURA_API_URL = process.env.NEXT_PUBLIC_HASURA_API_URL;
export const HASURA_ADMIN_SECRET = process.env.NEXT_PUBLIC_HASURA_ADMIN_SECRET;

export const MAIN_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_CONTRACT_MAIN;
export const GARAGE_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_CONTRACT_GARAGE;

export const POLY_GARAGE = process.env.NEXT_PUBLIC_CONTRACT_GARAGE_POLY;
export const SALE_CONTRACT = process.env.NEXT_PUBLIC_CONTRACT_SALE;

export const RACE_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_CONTRACT_RACE;

export const PUNK_UPGRADE_ADDRESS =
  process.env.NEXT_PUBLIC_CONTRACT_PUNK_UPGRADE;

export const LEASING_CONTRACT_ADDRESS =
  process.env.NEXT_PUBLIC_CONTRACT_LEASING;

export const ADMIN_COOKIE_PASSWORD = process.env.NEXT_PUBLIC_ADMIN_COOKIE;
export const JWT_SECRET = process.env.NEXT_PUBLIC_JWT_SECRET;
export const LEASING_ADMIN_SECRET =
  process.env.NEXT_PUBLIC_LEASING_ADMIN_SECRET;

export const GOOGLE_TAG_MANAGER_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID;

export const SENTRY_DSN =
  env === "dev" ? null : process.env.NEXT_PUBLIC_SENTRY_DSN;
export const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENV;

export const DISCORD_SERVER = process.env.NEXT_PUBLIC_DISCORD_SERVER;
export const DISCORD_CHANNEL = process.env.NEXT_PUBLIC_DISCORD_CHANNEL;

export const CHANNEL_ETH_ADDRESS = process.env.NEXT_PUBLIC_ETH_CHANNEL;
export const CHANNEL_POLY_ADDRESS = process.env.NEXT_PUBLIC_POLY_CHANNEL;
export const ERC_20_ETH = process.env.NEXT_PUBLIC_ERC_20_ETH;
export const ERC_20_POLY = process.env.NEXT_PUBLIC_ERC_20_POLY;
export const ERC_20_POLY_TEST = process.env.NEXT_PUBLIC_ERC_20_POLY_TEST;
export const ETH_RPC = process.env.NEXT_PUBLIC_ALCHEMY_API_URL;
export const POLY_RPC = process.env.NEXT_PUBLIC_ALCHEMY_API_URL_POLY;

export const POLY_MUMBAI_RPC =
  process.env.NEXT_PUBLIC_ALCHEMY_API_URL_POLY_MUMBAI;
export const POLY_GARAGE_MUMBAI =
  process.env.NEXT_PUBLIC_CONTRACT_GARAGE_POLY_MUMBAI;
export const ALCHEMY_API_POLY_MUMBAI =
  process.env.NEXT_PUBLIC_ALCHEMY_API_POLY_MUMBAI;

const polyGarageContract = require("./pages/api/data/poly_garage.json");

export const GARAGE_UPGRADE_CONTRACT = polyGarageContract;
export const GARAGE_UPGRADE_CONTRACT_ADDRESS = POLY_GARAGE;
export const GARAGE_UPGRADE_RECIPIENT = GARAGE_CONTRACT_ADDRESS;
