import { gql } from "@apollo/client";
import { VEHICLE_FIELDS, VEHICLE_STATS } from ".";

export const VEHICLES_FOR_RACE_FIELDS = gql`
  ${VEHICLE_FIELDS}
  ${VEHICLE_STATS}

  fragment VehicleForRaceFields on token_metadata {
    attributes {
      transportation_mode
      vehicle_type
    }
    ...VehicleFields
    ...VehicleStats
    freightPunk: token {
      punk {
        token_id
        image
      }
    }
  }
`;
