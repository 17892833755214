import { gql } from "@apollo/client";
import { VEHICLE_FOR_LEASE_FIELDS } from "../fragments";

export const VEHICLE_TO_LEASE = gql`
  ${VEHICLE_FOR_LEASE_FIELDS}

  query VehicleToLease($id: Int) {
    token_leases(
      where: { status: { _eq: "confirmed" }, token_id: { _eq: $id } }
    ) {
      ...VehicleForLeaseFields
      token {
        address
        racer {
          address
          username
        }
        punk {
          name
          image
          xp: xp_earned
          level
        }
      }
    }
  }
`;
