import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useAtom, useAtomValue } from "jotai";
import { apolloClient } from "src/lib/apollo";
import { FREIGHT_PUNKS } from "src/graphql/queries";
import { userWalletAtom } from "../../store";
import { userFreightPunksAtom } from "../../store/punks";

export default function useFreightPunks() {
  const { address } = useAtomValue(userWalletAtom);
  const [punks, setFreightPunks] = useAtom(userFreightPunksAtom);

  const [fetchPunks, { loading }] = useLazyQuery(FREIGHT_PUNKS, {
    client: apolloClient,
    variables: { address },
    onCompleted: ({ freightpunk_metadata: data }) => {
      data.length && setFreightPunks(data);
    },
  });

  useEffect(() => address && fetchPunks(), [address, fetchPunks]);

  return { punks, loading };
}
