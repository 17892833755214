import { gql } from "@apollo/client";
import { PUNK_FIELDS } from "../fragments";

export const FREIGHT_PUNK = gql`
  ${PUNK_FIELDS}

  query FreightPunk($tokenId: Int!) {
    freightpunk_metadata(where: { token_id: { _eq: $tokenId } }) {
      ...PunkFields
    }
  }
`;
