import { gql } from "@apollo/client";

export const UPGRADES_HISTORY = gql`
  query UpgradesHistory($tokenId: Int) {
    upgrade_transactions(where: { token_id: { _eq: $tokenId } }) {
      transaction_hash
      token_id
      upgrade_id
      created_at
      upgrade {
        name
        short_name
        price
      }
    }
  }
`;
