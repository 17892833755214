import { gql } from "@apollo/client";
import { VEHICLE_FIELDS } from "./vehicle.fragment";
import { VEHICLE_STATS } from "./vehicleStats.fragment";

export const VEHICLE_FOR_LEASE_FIELDS = gql`
  ${VEHICLE_FIELDS}
  ${VEHICLE_STATS}

  fragment VehicleForLeaseFields on token_leases {
    id
    token_id
    lease_terms
    lease_fee
    winnings_split
    duration
    max_races
    races_raced
    metadata {
      ...VehicleFields
      ...VehicleStats
      attributes {
        vehicle_type
        transportation_mode
      }
    }
  }
`;
