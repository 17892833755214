import { gql } from "@apollo/client";

export const UPGRADES = gql`
  query Upgrades($tokenId: Int!) {
    upgraded_stats(
      where: { token_id: { _eq: $tokenId } }
      order_by: { upgrade_id: asc }
    ) {
      upgrade {
        id
        name
        short_name
        price
        type
      }
      max_capacity
      max_range
      max_speed
      fuel_efficiency
      emission_rate
      owned
      applied
    }
  }
`;
