import { gql } from "@apollo/client";

export const VEHICLE_FIELDS = gql`
  fragment VehicleFields on token_metadata {
    token_id
    name
    image
    wins
    podium_places
    races_raced
    token {
      status
    }
  }
`;
