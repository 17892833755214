import { gql } from "@apollo/client";

export const UPGRADES_APPLIED = gql`
  query UpgradesApplied($tokenId: Int!) {
    upgraded_stats(
      where: { token_id: { _eq: $tokenId }, applied: {_eq: true} }
      order_by: { upgrade_id: asc }
    ) 
      {
        upgrade_id
        upgrade{
          name
          type
          short_name
        }
      }
    }
`;
