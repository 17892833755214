import { gql } from "@apollo/client";

export const FREIGHT_PUNK_SKILLS_APPLIED = gql`
  query FreightPunkSkillsApplied($tokenId: Int!) {
    freightpunk_skills_applied(where: { punk_id: { _eq: $tokenId } }) {
      skill_id
      skill {
        name
        tier
      }
    }
  }
`;
