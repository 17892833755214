import { gql } from "@apollo/client";

export const FREIGHT_PUNK_SKILLS = gql`
  query FreightPunkSkills($branch: String!) {
    freightpunk_skills(
      where: { type: { _eq: $branch } }
      order_by: { id: asc }
    ) {
      id
      name
      description
      type
      tier
      price
      req_level
      prereq
    }
  }
`;
