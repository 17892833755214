import { PUNK_FIELDS } from "./punk.fragment";
import { RACE_FIELDS } from "./race.fragment";
import { VEHICLE_FIELDS } from "./vehicle.fragment";
import { VEHICLE_STATS } from "./vehicleStats.fragment";
import { VEHICLE_FOR_LEASE_FIELDS } from "./vehicleForLease.fragment";
import { VEHICLES_FOR_RACE_FIELDS } from "./vehicleForRace.fragment";

export {
  PUNK_FIELDS,
  RACE_FIELDS,
  VEHICLE_FIELDS,
  VEHICLE_STATS,
  VEHICLE_FOR_LEASE_FIELDS,
  VEHICLES_FOR_RACE_FIELDS,
};
