import { gql } from "@apollo/client";

export const PUNK_FIELDS = gql`
  fragment PunkFields on freightpunk_metadata {
    token_id
    name
    image
    xp_earned
    level
    skill_points
    vehicle {
      metadata {
        token_id
        name
        image
      }
    }
  }
`;
