import { Toaster as Toasts } from "react-hot-toast";

export default function Toaster() {
  return (
    <Toasts
      position="bottom-right"
      containerStyle={{ bottom: 40 }}
      toastOptions={{
        duration: 4000,
        success: {
          style: {
            color: "white",
            // background: "#11D552",
            background: "#292F3C",
          },
          iconTheme: {
            primary: "white",
            secondary: "#292F3C",
          },
        },
        error: {
          style: {
            color: "white",
            background: "#FF2A5A",
          },
          iconTheme: {
            primary: "white",
            secondary: "#FF2A5A",
          },
        },
      }}
    />
  );
}
