import { gql } from "@apollo/client";
import { RACE_FIELDS } from "../fragments";

export const NEXT_TO_RACE_RACES_NO_FILTER = gql`
  ${RACE_FIELDS}

  query NextToRaceRaces($raceClasses: String_comparison_exp) {
    races(
      where: { status: { _eq: "next_to_race" }, class: $raceClasses }
      order_by: { start: asc }
      limit: 50
    ) {
      ...RaceFields
    }
  }
`;
