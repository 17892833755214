import { gql } from "@apollo/client";

export const DRIVERS = gql`
  query Drivers($address: String!) {
    tokens(
      where: { address: { _regex: $address }, punk_id: { _is_null: false } }
    ) {
      punk_id
    }
  }
`;
