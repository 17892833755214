import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";

// User wallet
export const userWalletAtom = atom({
  address: null,
  network: null,
  provider: "Metamask",
  text: "Connect Wallet",
});

// WOF Token balance
export const userBalanceAtom = atom({
  eth: 0,
  polygon: 0,
  unclaimed: 0,
  locked: 0,
  unlocked: 0,
});

// The whole fleet of vehicles belonging to an address
export const userFleetAtom = atom(null);

// Main menu toggle
export const menuAtom = atom(false);

// Filter toggling between fleet of vehicles and Freight Punks
export const fleetFilterAtom = atom({ fleet: "wof" });

// Vehicle fleet sort by filter in Garage
export const fleetFiltersAtom = atomWithReset({
  raceClasses: [],
  ownership: "all",
  sortBy: { token_id: "asc" },
});

// Tab number in garage
export const garageTabAtom = atom(1);

// Current vehicle in garage
export const vehicleAtom = atom(null);

// Token balance dropdown (triggered by hover in desktop)
export const balanceViewAtom = atom({ shown: false, hovered: false });

// Announcement popover atom
export const announcementPopoverAtom = atom(false);

// Announcement title atom
export const announcementTitleAtom = atom(null);

// Calendar component input state
export const calendarInputAtom = atomWithReset({
  inputValue: "",
  fromDate: null,
  toDate: new Date(),
});

// Network warning (ETH->Polygon or Polygon->ETH) modal atom
export const networkWarningAtom = atom(false);

// Operation selected on Bridge page (Deposit / Withdraw)
export const bridgeOperationAtom = atom("Deposit");

// Filters in Merch Shop
export const merchFilterAtom = atomWithReset({
  categories: [],
});

// Merch cart open?
export const cartSidebarAtom = atom(false);

// Current cart data
export const cartAtom = atom([]);
