import { gql } from "@apollo/client";
import { RACE_FIELDS } from "../fragments";

export const UPCOMING_RACES = gql`
  ${RACE_FIELDS}

  query UpcomingRaces {
    races(where: { status: { _eq: "upcoming" } }) {
      ...RaceFields
    }
  }
`;
