import Script from "next/script";
import { GOOGLE_TAG_MANAGER_ID } from "src/constants";
import useRouteChange from "hooks/useRouteChange";

export default function GoogleTagManager() {
  // Initiate Google Tag Manager on every page
  useRouteChange((url) => {
    const pageEvent = { event: "pageview", page: url };

    window && window.dataLayer && window.dataLayer.push(pageEvent);
    return pageEvent;
  }, "end");

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG_MANAGER_ID}`}
      />
      <Script
        id="GTM-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');`,
        }}
      />
    </>
  );
}
