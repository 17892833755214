import { gql } from "@apollo/client";
import { RACE_FIELDS } from "../fragments";

export const RACES = gql`
  ${RACE_FIELDS}

  query Races(
    $limit: Int
    $sortBy: [races_order_by!]
    $status: String!
    $raceClasses: String_comparison_exp
    $userStatus: [races_bool_exp!]
    $entryFee: Int_comparison_exp
    $distance: Float_comparison_exp
    $period: timestamptz_comparison_exp
    $cargo: Int_comparison_exp
  ) {
    races(
      where: {
        status: { _eq: $status }
        class: $raceClasses
        entry_fee: $entryFee
        distance: $distance
        start: $period
        weight: $cargo
        _or: $userStatus # user in/not in race
      }
      order_by: $sortBy
      limit: $limit
    ) {
      ...RaceFields
    }
    races_aggregate(
      where: {
        status: { _eq: $status }
        class: $raceClasses
        entry_fee: $entryFee
        distance: $distance
        start: $period
        weight: $cargo
        _or: $userStatus
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
