import { gql } from "@apollo/client";
import { PUNK_FIELDS } from "../fragments";

export const FREIGHT_PUNKS = gql`
  ${PUNK_FIELDS}

  query FreightPunks($address: String!) {
    freightpunk_metadata(
      where: { punk: { address: { _regex: $address } } }
      order_by: { id: asc }
    ) {
      ...PunkFields
    }
  }
`;
