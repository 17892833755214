import { gql } from "@apollo/client";

export const RACER_RESULTS = gql`
  query RacerResults($address: String!) {
    race_results(
      where: { address: { _regex: $address } }
      order_by: { created_at: desc }
      limit: 5000
    ) {
      race {
        id
        name
        class
        race_vehicle_type: vehicle_type
        distance
        cargo
        weight
        weather
        terrain
        prize_pool
        entry_fee
        start
      }
      token_id
      punk_id
      prize
      place
      time
    }
  }
`;
