import { atom } from "jotai";

// Freight Punks belonging to an address
export const userFreightPunksAtom = atom(null);

// Current Punk as a driver on skills page
export const freightPunkAtom = atom(null);

// Skills available/applied
export const freightPunkSkillsAtom = atom({ all: null, applied: null });

// Active skill
export const freightPunkSkillAtom = atom({});

// Skill claim status
export const freightPunkSkillClaimStatus = atom("idle");

// Freight Punk to rename
export const freightPunkToRenameAtom = atom(null);
