import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { apolloClient } from "src/lib/apollo";
import { VEHICLES_LEASED } from "src/graphql/queries";
import { useSetAtom, useAtomValue } from "jotai";
import { userWalletAtom, fleetFiltersAtom } from "src/store";
import { userLeasesAtom } from "src/store/leasing";
import logError from "src/utils/logError";

export default function useLeasedVehicles() {
  const { address } = useAtomValue(userWalletAtom);
  const { raceClasses, sortBy } = useAtomValue(fleetFiltersAtom);
  const setVehicles = useSetAtom(userLeasesAtom);

  const initialClasses = ["Ground", "Water", "Air", "Space"];

  const [fetchVehicles, { refetch }] = useLazyQuery(VEHICLES_LEASED, {
    client: apolloClient,
    variables: {
      address,
      classes: raceClasses.length ? raceClasses : initialClasses,
      sortBy,
    },
    onCompleted: (data) => setVehicles(data.lease_contracts),
    onError: (error) => logError({ error }),
  });

  useEffect(() => {
    address && fetchVehicles();
  }, [address, raceClasses, sortBy, fetchVehicles]);

  return refetch;
}
